<!-- <div class="wrapper">
    <img class="wrapper" src="../../../assets/img/shared/logo-background-light-1920x1080.jpg" alt="Snapon Logo"/>
    <div class="container">
        <router-outlet></router-outlet>
    </div>
</div> -->
<app-loader></app-loader>
<div class="wrapper">
    <div class="wrapper-backdrop position-fixed"></div>
    <div class="container">
        <div class="logo-container position-relative">
            <img class="logo-container__img" src="../../../assets/img/shared/RIMS-logo-full.png" alt="Logo" style="visibility: hidden;">
        </div>
        <router-outlet></router-outlet>
    </div>
</div>